import { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import LinkComponent from '../LinkComponent/LinkComponent'

interface IPLVersion {
  slug?: string
  title?: string
}

const PennyLaneVersion = () => {
  const [PLVersion, setPLVersion] = useState<IPLVersion>({
    slug: '',
    title: '',
  })
  const blogData = useStaticQuery<Queries.GetRecentReleaseBlogQuery>(blogQuery)

  useEffect(() => {
    setPLVersion({
      slug: blogData.pennylaneCloud.blogPostForLatestRelease?.slug,
      title: blogData.pennylaneCloud.blogPostForLatestRelease?.title,
    })
  }, [])

  return PLVersion.slug && PLVersion.title ? (
    <LinkComponent
      href={`/blog/${PLVersion.slug}`}
      className="PennylaneRelease__link"
      testId="pennylane-release-link"
    >
      {PLVersion.title} <i className="bx bx-link-external"></i>
    </LinkComponent>
  ) : null
}

export default PennyLaneVersion

export const blogQuery = graphql`
  query GetRecentReleaseBlog {
    pennylaneCloud {
      blogPostForLatestRelease {
        title
        slug
      }
    }
  }
`
